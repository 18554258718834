import { template as template_7870042a44564ce29d313fd6656b1dc3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7870042a44564ce29d313fd6656b1dc3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
