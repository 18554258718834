import { template as template_522159d7dd494c6e8c95fd6d69dddb23 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_522159d7dd494c6e8c95fd6d69dddb23(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
