import { template as template_35763656b84c4dd29642bada8e955542 } from "@ember/template-compiler";
const FKControlMenuContainer = template_35763656b84c4dd29642bada8e955542(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
